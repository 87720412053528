<template>
  <div>
    <v-item-group>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="planViewModal = true"
            v-if="eduProg.eduPlan"
            color="primary"
            class="ma-1"
            ><v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>Посмотреть</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            v-if="eduProg.eduPlan"
            color="primary"
            class="ma-1"
            target="_blank"
            :href="
              $http.defaults.backDir + '/' + eduProg.eduPlan
            "
            ><v-icon>mdi-download-outline</v-icon>
          </v-btn>
        </template>
        <span>Скачать</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="planUploadModal=true"
            v-if="eduProg.eduPlan"
            color="orange"
            dark
            class="ma-1"
            ><v-icon>mdi-upload-outline</v-icon>
          </v-btn>
        </template>
        <span>Загрузить новый</span>
      </v-tooltip>
      <v-tooltip bottom v-if="eduProg.eduPlanOk == 1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :loading="saving"
            @click="verify(0)"
            color="red"
            dark
            class="ma-1"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Отменить подтверждение</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :loading="saving"
            @click="verify(1)"
            color="green"
            dark
            class="ma-1"
            ><v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>Подтвердить</span>
      </v-tooltip>
    </v-item-group>
    <v-dialog v-model="planViewModal" fullscreen>
      <v-card>
        <v-toolbar color="#365d84" flat dark style="max-height: 64px">
          <v-toolbar-title>Учебный план</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="planViewModal = false" small fab color="red" dark>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />
        <v-card-text class="ma-0 pa-0">
          <embed
            v-if="eduProg.eduPlan"
            :src="
              $http.defaults.backDir + '/' + eduProg.eduPlan
            "
            style="width: 100%; height: calc(100vh - 90px)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="planUploadModal" width="600">
    <eduPlanUpoad :eduProgId="eduProg.id" @planChanged="planChanged($event)" @close="planUploadModal=false"/>
    </v-dialog>
  </div>
</template>


<script>
import eduPlanUpoad from "./eduPlanUpload.vue";
export default {
  name: "StarterPage",
  props: ["eduProg"],
  data: () => ({
    newFile: [],
    saving: false,
    planViewModal: false,
    planUploadModal: false,
  }),
  components: {
    eduPlanUpoad,
  },
  methods: {
    planChanged(plan) {
      this.eduProg.eduPlanOk = 0;
      this.eduProg.eduPlan = plan;
      this.planUploadModal=false
    },
    verify(com) {
      this.saving = true;
      this.$http
        .get("edu-prog/edu-plan-accept", {
          params: {
            eduProgId: this.eduProg.id,
            com: com,
          },
        })
        .then((response) => {
          this.eduProg.eduPlanOk = response.data;
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
  mounted() {},
};
</script>
<template>
  <div class="d-flex justify-space-between">
    <b>Курсов: {{ courses.length }}. Периодов: {{ structure.length }}</b>

    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="ml-3 mt-0" dark v-bind="attrs" v-on="on">
          <v-icon>mdi-circle-edit-outline</v-icon></v-btn
        >
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2 mt-3 justify-space-between">
          Изменить
          <v-btn fab @click="dialog = false" small
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-list-item v-for="(courseData, cIndex) in courses" :key="cIndex">
            <v-list-item-content>
              <v-list-item-title
                >Курс {{ courseData[0].course
                }}<deleteBtn
                  @remove="fetchData()"
                  title="Удалить курс"
                  text="Вы действительно хотите удалить этот курс? Дальнейшие курсы и периоды будут сдвинуты"
                  :actionHref="
                    'structure/course-delete?eduProgId=' +
                    eduProgId +
                    '&num=' +
                    cIndex
                  "
              /></v-list-item-title>
              <p
                v-for="period in courseData"
                :key="period.id"
                class="my-1 mx-1"
              >
                - Период {{ period.order
                }}<deleteBtn
                  @remove="fetchData()"
                  title="Удалить период"
                  text="Вы действительно хотите удалить этот период? Дальнейшие периоды будут сдвинуты, если в курсе больше нет периодов он будет также удалён"
                  :actionHref="'structure/period-delete?periodId=' + period.id"
                />
              </p>
              <p class="my-1 mx-1">
                <v-btn :loading="saving"
                  small
                  color="primary"
                  @click="periodAdd(courseData[0].course)"
                  style="max-width: fit-content"
                  ><v-icon>mdi-plus</v-icon> Добавить период</v-btn
                >
              </p>
              <v-divider />
            </v-list-item-content>
          </v-list-item>
          <v-btn @click="courseAdd()" :loading="saving"
            ><v-icon>mdi-plus</v-icon> Добавить курс</v-btn
          >
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import deleteBtn from "../delete.vue";
export default {
  props: ["eduProgId"],
  data() {
    return {
      dialog: false,
      saving: false,
      structure: [],
      rules: {
        required: [(v) => !!v || "Не должно быть пустым"],
      },
    };
  },
  components: {
    deleteBtn,
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    courses() {
      return this.groupBy(this.structure, "course");
    },
  },
  watch: {
    structure() {
      this.$emit("structureUpdate", this.structure);
    },
  },
  methods: {
    groupBy(arr, prop) {
      const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
      arr.forEach((obj) => map.get(obj[prop]).push(obj));
      return Array.from(map.values());
    },
    periodAdd(course) {
      this.saving = true;
            this.$http
        .get("structure/period-add",{
          params:{
            eduProgId:this.eduProgId,
            course: course
          }
        })
        .then(() => {
          this.fetchData();
        })
        .catch(() => {
          alert("Ошибка!");
        })
        .finally(() => {
          this.saving = false;
        });
    },
    courseAdd() {
      this.saving = true;
      this.$http
        .get("structure/course-add?eduProgId=" + this.eduProgId)
        .then((response) => {
          this.structure.push(response.data);
        })
        .catch(() => {
          alert("Ошибка!");
        })
        .finally(() => {
          this.saving = false;
        });
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get("edu-prog/structure?eduProgId=" + this.$route.params.id)
        .then((response) => {
          this.structure = response.data;
        })
        .catch(() => {
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
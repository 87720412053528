<template>
  <v-card elevation="0" class="w-100 ma-0 rounded-0 h-100 d-flex flex-column" color="rgb(255 255 255 / 80%)">
    <v-toolbar color="rgb(90 126 161)" flat dark>
      <v-toolbar-title>Основная информация</v-toolbar-title>
      <v-spacer></v-spacer>

      
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text v-if="!loading" class="pa-0 h-100" >
      <v-list color="transparent" class="h-100">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Наименование</v-list-item-title>
            <v-list-item-subtitle>
              {{ eduProg.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item two-line  v-if="eduProg.type=='vo' || eduProg.type=='spo'">
          <v-list-item-content>
            <v-list-item-title>Профиль</v-list-item-title>
            <v-list-item-subtitle> {{ eduProg.profile }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Нормативный срок освоения, утвержденный образовательной
              организацией (в часах)</v-list-item-title>
            <v-list-item-subtitle>{{ eduProg.term }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Форма обучения</v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="eduProg.studyForm == 1">Очная</span>
              <span v-if="eduProg.studyForm == 2">Очно-заочная</span>
              <span v-if="eduProg.studyForm == 3">Заочная</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item three-line  v-if="eduProg.type=='vo' || eduProg.type=='spo'">
          <v-list-item-content>
            <v-list-item-title>Код по ОКСО</v-list-item-title>
            <v-list-item-subtitle>{{ eduProg.okso?.code??'Не задано' }}</v-list-item-subtitle>
            <b>{{ eduProg.okso?.name??'Не задано' }}</b>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item two-line  v-if="eduProg.type=='vo' || eduProg.type=='spo'">
          <v-list-item-content>
            <v-list-item-title>Образовательный стандарт</v-list-item-title>
            <v-list-item-subtitle>{{ eduProg.fgos.code }}</v-list-item-subtitle>
            <b>{{ eduProg.fgos.name }}</b>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Квалификации выпускника</v-list-item-title>
            <p v-for="skill in eduProg.skills" :key="skill.id" class="text-body-2 font-weight-bold">{{ skill.name }}</p>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item two-line v-if="1!==1">
          <v-list-item-content>
            <v-list-item-title class="d-flex justify-space-between">Учебный план
              <v-chip v-if="eduProg.eduPlanOk == 1" color="green" dark>Подтверждён</v-chip>
              <v-chip v-else color="red" dark>Не подтверждён</v-chip>
            </v-list-item-title>
            <eduPlan :eduProg="eduProg" />
          </v-list-item-content>
        </v-list-item>
        <v-divider />

         
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Структура</v-list-item-title>
            <structureEdit v-if="!loading" :btn="true" :eduProgId="eduProg.id"
              @structureUpdate="$emit('structureUpdate',$event)" />
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-card-text>
    <div v-else class="w-100 h-100 d-flex align-center justify-center">
        <v-progress-circular 
    
      :size="60"
      :width="7"
      color="purple"
      indeterminate
    ></v-progress-circular>
      </div>
  </v-card>
</template>


<script>
import structureEdit from "./structure/structureEdit.vue";
import eduPlan from "./info/eduPlan.vue";


export default {
  name: "StarterPage",
  props: ["loading", "eduProg"],
  data: () => ({}),
  components: {
    structureEdit,
    eduPlan
  },
  methods: {

  }
};
</script>
<template>
  <v-card elevation="12">
    <v-toolbar color="#365d84" flat dark>
      <v-toolbar-title>Учебный план</v-toolbar-title><v-spacer></v-spacer><v-btn fab small color="red" @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text class="text-center">
      <v-file-input
        chips
        @change="errors=[]"
        :rules="fileRules"
        accept="application/pdf"
        v-model="file"
        :error="errors ? true : false"
        :error-messages="errors ? errors : []"
        label="Учебный план (копия в формате pdf)"
      ></v-file-input
    >
    <v-btn @click="upload()" :loading="saving" color="green" dark>Загрузить</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    props:['eduProgId'],
  data() {
    return {
      file: null,
      saving:false,
      errors: [],
      fileRules: [
        (value) =>
          !value || value.size < 20000000 || "Максимальный размер файла 20 MB!",
      ],
    };
  },
  methods:{
    upload(){
        this.saving = true;
              let formData = new FormData();
      formData.append("file", this.file);
      this.$http
        .post("edu-prog/edu-plan-upload?eduProgId=" + this.eduProgId, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$emit('planChanged',response.data)
        })
        .catch((e) => {
          if (e.response.data) {
              this.errors= e.response.data;
          } else {
            alert("error!");
          }
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
};
</script>

<style>
</style>